<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 奖励规则
 * @Date: 2020-11-18 15:32:53
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-19 17:49:28
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/creditCard/Rewardrules.vue
-->
<template>
  <div class="content">
    <table class="tablere">
      <caption>{{ synopsis }}</caption>
      <tr>
        <th v-for="(item,index) in header" :key="index">{{ item }}</th>
      </tr>
      <tr v-for="(item,index) in contentlist" :key="index">
        <td><img :src="item.logo" alt="">
          <div style="font-size: 12px;">{{ item.bank }}</div>
        </td>
        <td>{{ item.rewardAmount }}</td>
        <td>{{ item.settlementConditions }}</td>
        <td>{{ item.settlementMethod }}</td>
        <td>{{ item.settlementPeriod }}</td>
      </tr>
    </table>
    <div class="settlement">
      <div class="title">{{ share_rules.settlementShareRules }}</div>
      <div class="txtcen">{{ share_rules.content }}</div>
    </div>
    <div class="settlement">
      <div class="title">{{ rules.settlementRules }}</div>
      <div class="txtcen">{{ rules.content }}</div>
    </div>
    <div class="settlement">
      <div class="title">{{ cycle.settlementPeriod }}</div>
      <div class="txtcen">{{ cycle.content }}</div>
    </div>
    <div class="h" />
  </div>
</template>

<script>
import { rewardDescription } from '@/services/comeOn.js'

export default {
  data() {
    return {
      synopsis: '',
      header: '',
      contentlist: '',
      rules: '',
      cycle: '',
      share_rules: {}
    }
  },
  created() {
    this.getrewardDescription()
  },
  methods: {
    getrewardDescription() {
      rewardDescription({
        type: 2,
        mid: localStorage.getItem('uid')
      }).then(res => {
        if (Number(res.code) === 200) {
          this.synopsis = res.data.synopsis
          this.header = res.data.header
          this.contentlist = res.data.content
          this.rules = res.data.rules
          this.cycle = res.data.cycle
          this.share_rules = res.data.share_rules
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.h {
  height: 40px;
}

.content {
  padding: 10px;
  background-color: #f8f8f8;
  min-height: 100vh;
  font-size: 12px;

  .tablere {
    caption {
      color: #090808;
      font-size: 14px;
      padding: 10px;
      font-weight: 500;
    }

    th {
      border: 1px solid #E7E7E7;
      background-color: #eeeeee;
      padding: 8px;
      font-size: 11px;
      text-align: center;
    }

    td {
      border: 1px solid #E7E7E7;
      padding: 8px;
      text-align: center;

      img {
        height: 11px;
      }
    }
  }

  .settlement {
    margin-top: 25px;

    .title {
      text-align: left;
      color: #090808;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .txtcen {
      color: #090808;
      font-size: 12px;
      line-height: 15px;
      text-align: left;
    }
  }
}
</style>

